<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} IP Pool</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="pd-0"
                >
                  <v-text-field
                    v-model="form.name"
                    label="IP Pool name"
                    placeholder="e.g pool1"
                    :rules="[
                      v => !!v || 'Name is required',
                    ]"
                    required
                    :error="form.errors.has('name')"
                    :error-messages="form.errors.get('name')"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="pd-0"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      sm="5"
                    >
                      <v-text-field
                        v-model="form.start_ip"
                        label="Start IP"
                        placeholder="e.g. 192.168.1.1"
                        :rules="[
                          v => !!v || 'Start IP is required',
                        ]"
                        :error="form.errors.has('start_ip')"
                        :error-messages="form.errors.get('start_ip')"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="1"
                      class="d-none d-md-flex justify-center align-center"
                    >
                      <v-subheader class="px-0 display-1 mb-7">
                        -
                      </v-subheader>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="5"
                    >
                      <v-text-field
                        v-model="form.end_ip"
                        label="End IP"
                        placeholder="e.g. 192.168.1.254"
                        :error="form.errors.has('end_ip')"
                        :error-messages="form.errors.get('end_ip')"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12">
                  <v-select
                    v-model="form.router_id"
                    :items="displayRouters"
                    item-text="displayText"
                    item-value="id"
                    label="Select a router"
                    :rules="[
                      v => !!v || 'Router is required',
                    ]"
                    :loading="isRoutersLoading"
                    :error="form.errors.has('router_id')"
                    :error-messages="form.errors.get('router_id')"
                    outlined
                  ></v-select>
                  <span
                    v-if="!isRoutersLoading && routers.length === 0"
                  >
                    There are no any Routers available. Please go to <router-link to="/routers">Routers</router-link> page and create one.
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import axios from 'axios'

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
    ipPool: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isRoutersLoading: false,
    form: new Form({
      name: '',
      start_ip: '',
      end_ip: '',
      router_id: '',
      id: '',
    }),
    action: 'Save',
    routers: [],
  }),
  computed: {
    displayRouters() {
      return this.routers.map(router => ({
        ...router,
        displayText: `${router.station.name} Router`,
      }))
    },
  },
  watch: {
    ipPool() {
      if (Object.keys(this.ipPool).length > 0) {
        this.form.update(this.ipPool)
        this.action = 'Update'
      } else {
        this.action = 'Save'
      }
    },
  },
  created() {
    this.fetchRouters()
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.form.resetValidation()
      this.form.errors.clear()
      this.$refs.form.reset()
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        console.log('form is valid')
        if (this.action === 'Save') {
          console.log('save')
          this.saveIpPool()
        } else {
          console.log('update')
          this.updateIpPool()
        }
      }
    },
    saveIpPool() {
      console.log('save ip pool')
      console.log(this.form)
      this.form
        .post('ip-pools')
        .then(response => {
          this.$toast.success('IP Pool added successfully')
          this.$emit('close')
          this.$emit('ipPool-added', response.data)
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    updateIpPool() {
      this.form
        .put(`ip-pools/${this.form.id}`)
        .then(response => {
          this.$toast.success('IP Pool updated successfully')
          this.$emit('close')
          this.$emit('ipPool-updated', response.data)
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    fetchRouters() {
      this.isRoutersLoading = true
      axios.get('/routers?perPage=all')
        .then(response => {
          this.routers = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isRoutersLoading = false
        })
    },
  },
}
</script>
