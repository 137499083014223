<template>
  <v-row justify="center">
    <v-dialog
      v-model="value"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="importRouter()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="form.router_id"
                    :items="displayRouters"
                    item-text="displayText"
                    item-value="id"
                    label="Select a router"
                    :rules="[
                      v => !!v || 'Router is required',
                    ]"
                    :loading="isRoutersLoading"
                    :error="form.errors.has('router_id')"
                    :error-messages="form.errors.get('router_id')"
                    outlined
                  ></v-select>
                  <span
                    v-if="!isRoutersLoading && routers.length === 0"
                  >
                    There are no any Routers available. Please go to <router-link to="/routers">Routers</router-link> page and create one.
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              Import
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import axios from 'axios'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isRoutersLoading: false,
    form: new Form({
      router_id: '',
    }),
    routers: [],
  }),
  computed: {
    displayRouters() {
      return this.routers.map(router => ({
        ...router,
        displayText: `${router.station.name} Router`,
      }))
    },
  },
  created() {
    this.fetchRouters()
  },
  methods: {
    closeDialog() {
      this.$emit('input', false)
      this.$refs.form.resetValidation()
      this.form.errors.clear()
      this.$refs.form.reset()
    },
    importRouter() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form
          .post(`${this.url}/${this.form.router_id}`)
          .then(response => {
            this.$toast.success('Imported successfully')
            this.$emit('input', false)
            this.$emit('imported', response.data)
            this.$refs.form.reset()
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
          })
      }
    },
    fetchRouters() {
      this.isRoutersLoading = true
      axios.get('/routers?perPage=all')
        .then(response => {
          this.routers = response.data
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isRoutersLoading = false
        })
    },
  },
}
</script>
