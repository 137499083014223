<template>
  <div class="ip-pools">
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        class="text-md-right"
        cols="12"
        md="3"
      >
        <v-btn
          v-if="can('ip-pool-create')"
          color="primary"
          outlined
          @click="showImportDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiImport }}
          </v-icon>
          Import from Router
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="text-md-right"
      >
        <v-btn
          v-if="can('ip-pool-create')"
          color="primary"
          class="mb-5"
          @click="showAddIpPoolDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          Add IP Pool
        </v-btn>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-card>
        <v-card-title>
          <v-row
            justify="center"
            class="mb-0"
          >
            <v-col
              cols="12"
              md="4"
            >
              <refresh-button
                :loading="isIpPoolsRefreshing"
                @click="refreshIpPools()"
              />
            </v-col>
            <v-col
              md="6"
              sm="12"
              class="text-center"
            >
              <search-input
                v-model="search"
                :filters="searchFilters"
                :active-filter="searchColumn"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
            <v-spacer class="d-none d-md-inline" />
          </v-row>
        </v-card-title>
        <v-expand-transition>
          <v-row
            v-if="selected.length > 0 && can('ip-pool-delete')"
            class="mb-2"
          >
            <v-col class="text-md-right">
              <v-btn
                color="error"
                class="ma-2"
                @click="showConfirmBulkDeleteDialog = true"
              >
                <v-icon
                  left
                  size="22"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
                Delete Selected
              </v-btn>
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="ipPools"
          :loading="isIpPoolsLoading"
          :server-items-length="pagination.totalItems"
          :options.sync="options"
          :footer-props="footerProps"
          show-select
        >
          <template v-slot:item.station="{ item }">
            {{ item.router.station.name }}
            Router
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip
              v-if="can('ip-pool-edit')"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  ma-4
                  v-bind="attrs"
                  text
                  icon
                  @click="editIpPool(item)"
                  v-on="on"
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip
              v-if="can('ip-pool-delete')"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  ma-4
                  v-bind="attrs"
                  text
                  icon
                  @click="showDeleteIpPoolDialog(item.id)"
                  v-on="on"
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiDelete }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-expand-transition>
    <add-ip-pool-dialog
      :show-dialog="showAddIpPoolDialog"
      :ip-pool="ipPool"
      @close="
        showAddIpPoolDialog = false
        ipPool = {}
      "
      @ipPool-added="onIpPoolAdded"
      @ipPool-updated="onIpPoolUpdated"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteIpPool()"
    />
    <confirm-dialog
      :show-dialog="showConfirmBulkDeleteDialog"
      :message="'Are you sure you want to delete ' + selected.length + ' records?'"
      :is-agree-button-loading="isConfirmBulkDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmBulkDeleteDialog = false"
      @agree="deleteSelectedIpPools()"
    />
    <import-from-router-dialog
      v-model="showImportDialog"
      title="Import IP Pools"
      url="/import-from-router/ip-pools"
      @close="showImportDialog = false"
      @imported="refreshIpPools"
    />
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import {
  mdiMagnify,
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiImport,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import AddIpPoolDialog from '@/components/dialogs/AddIpPoolDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import SearchInput from '@/components/partials/SearchInput.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import ImportFromRouterDialog from '@/components/dialogs/ImportFromRouterDialog.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    AddIpPoolDialog,
    ConfirmDialog,
    breadcrumb: BreadCrumb,
    SearchInput,
    RefreshButton,
    ImportFromRouterDialog,
  },
  mixins: [hasPermission],
  data() {
    return {
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiPlus,
        mdiImport,
      },
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Networking',
          disabled: true,
          to: { name: 'routers' },
        },
        { text: 'IP Pools', disabled: true },
      ],
      ipPools: [],
      selected: [],
      isIpPoolsLoading: true,
      isPageLoaded: false,
      isIpPoolsRefreshing: false,
      isConfirmDeleteDialogButtonLoading: false,
      isConfirmBulkDeleteDialogButtonLoading: false,
      showAddIpPoolDialog: false,
      showConfirmDeleteDialog: false,
      showConfirmBulkDeleteDialog: false,
      showImportDialog: false,
      search: '',
      searchFilters: ['Name', 'Start Ip', 'End Ip'],
      searchColumn: 'Name',
      ipPool: {},
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      footerProps: {
        itemsPerPageOptions: [10, 20, 50],
      },
      pagination: {
        totalItems: 0,
      },
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    headers() {
    // Start with the base headers
      const headers = [
        { text: 'Name', value: 'name' },
        { text: 'Start IP', value: 'start_ip' },
        { text: 'End IP', value: 'end_ip' },
        { text: 'Actions', value: 'actions', sortable: false },
      ]

      // If selectedStation is 'all', insert the 'Router' header
      if (this.selectedStation === 'all') {
        headers.splice(3, 0, { text: 'Router', value: 'station' })
      }

      return headers
    },
  },
  watch: {
    options: {
      handler() {
        if (this.isPageLoaded) {
          this.getIpPools()
        }
      },
      deep: true,
    },
    search: {
      handler: _.debounce(function () {
        this.getIpPools()
      }, 500),
    },
    selectedStation() {
      if (this.isPageLoaded) {
        this.getIpPools()
      }
    },
  },
  mounted() {
    if (!this.can('ip-pool-edit') && !this.can('ip-pool-delete')) {
      this.removeActionsHeader()
    }
    this.getIpPools()
    this.isPageLoaded = true
  },
  methods: {
    getIpPools() {
      this.isIpPoolsLoading = true

      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options
      const sortOrder = sortDesc[0] ? 'desc' : 'asc'
      axios.get('ip-pools', {
        params: {
          paginate: true,
          search: this.search,
          column: this.searchColumn,
          stationId: this.selectedStation,
          sortBy: sortBy[0],
          sortDirection: sortOrder,
          page,
          perPage: itemsPerPage,
        },
      })
        .then(response => {
          this.ipPools = response.data.data
          this.pagination.totalItems = response.data.total
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isIpPoolsLoading = false
          this.isIpPoolsRefreshing = false
        })
    },
    refreshIpPools() {
      this.isIpPoolsRefreshing = true
      this.getIpPools()
    },
    editIpPool(item) {
      this.ipPool = item
      this.showAddIpPoolDialog = true
    },
    showDeleteIpPoolDialog(itemId) {
      this.ipPool = this.ipPools.find(ipPool => ipPool.id === itemId)
      this.showConfirmDeleteDialog = true
    },
    deleteSelectedIpPools() {
      this.isConfirmBulkDeleteDialogButtonLoading = true
      const deleteRequests = this.selected.map(ipPool => axios.delete(`ip-pools/${ipPool.id}`))
      Promise.all(deleteRequests)
        .then(() => {
          this.getIpPools()
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isConfirmBulkDeleteDialogButtonLoading = false
          this.showConfirmBulkDeleteDialog = false
        })
    },
    deleteIpPool() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios.delete(`ip-pools/${this.ipPool.id}`)
        .then(() => {
          this.getIpPools()
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    onIpPoolAdded() {
      this.refreshIpPools()
    },
    onIpPoolUpdated() {
      this.refreshIpPools()
    },
    onPageChange() {
      this.getRouters()
    },
    onSearchFilterChange(filter) {
      this.searchColumn = filter
    },
    removeActionsHeader() {
      this.headers.pop()
    },
  },
}
</script>
